var generic = generic || {};
var prodcat = prodcat || {};

prodcat.data = prodcat.data || {};
var site = site || {};
var presentClearpay = presentClearpay || {};

(function ($) {
  Drupal.behaviors.productShadePickerV1 = {
    /**
     * Flag to ensure this JS is only attached once.
     * @type {boolean}
     */
    attached: false,

    /**
     * Puts the currently selected SKU's shade description on the page.
     * @param {string} skuId - the SKU's ID.
     * @param {Object} $product - jQuery DOM element context.
     * @returns {undefined}
     */
    setShadeDesc: function (skuId, $product) {
      var $shadeDesc = $('.js-product-shade-desc', $product);
      var sku;

      if (skuId) {
        sku = prodcat.data.getSku(skuId);
        if (sku && sku.SHADE_DESCRIPTION) {
          $shadeDesc.html(sku.SHADE_DESCRIPTION);
        }
      }
    },

    /**
     * Hides or displays the "egift" class elements (e.g. the Egift button)
     * based on the setting of the SKU's EGIFT_ENABLED flag.
     *
     * @param {string} skuId - the SKU's ID.
     * @returns {undefined}
     */
    toggleEgift: function (skuId) {
      var sku;
      var $egift = $('.js-egift-cta');

      if (skuId) {
        sku = prodcat.data.getSku(skuId);
        if (sku && sku.EGIFT_ENABLED) {
          $egift.removeClass('hidden');
        } else {
          $egift.addClass('hidden');
        }
        var $egiftQuestion = $('.js-egift-info');

        $egiftQuestion.on('click', function (e) {
          e.preventDefault();
          if (site.templates && site.templates['product_egift_faq']) {
            var rendered = site.template.get({
              name: 'product_egift_faq'
            });

            generic.overlay.launch({
              content: rendered,
              height: 460,
              width: 580
            });
          }
        });
      }
    },

    /**
     * Determines whether the supplied hex color has poor luminance.
     * @param {string} hexValue - color in hexadecimal form.
     * @returns {boolean} true if the supplied color is dark.
     */
    isDark: function (hexValue) {
      if (hexValue === '') {
        return true;
      }

      // Strip the lead pound sign if it's there.
      if (hexValue.indexOf('#') === 0) {
        hexValue = hexValue.substring(1);
      }

      var rgb = parseInt(hexValue, 16);
      var red = (rgb >> 16) & 0xff;
      var green = (rgb >> 8) & 0xff;
      var blue = (rgb >> 0) & 0xff;
      // A standard calculation for luminance found on the web
      // based on the human eyeball's sensitivity to red, green,
      // and blue light. Pretty interesting!
      var luminance = 0.2126 * red + 0.7152 * green + 0.0722 * blue;

      return luminance < 40;
    },

    /**
     * Gives the active state to the shade matching the sku's base id
     * and loads the shade description too.
     * @param {Object} $product - jQuery DOM element context.
     * @returns {undefined}
     */
    makeSkuBaseIdActive: function ($product) {
      var skuBaseId = $product.data('sku-base-id');

      $('select.js-sku-menu', $product).val(skuBaseId);
      $('.js-product-shade', $product).removeClass('active');
      $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', $product).addClass('active');
      this.setShadeDesc(skuBaseId, $product);
      this.toggleEgift(skuBaseId);
    },

    /**
     * Applies extra BEM class names to shades with low luminance.
     * @param {Object} $product - jQuery DOM element context.
     * @returns {undefined}
     */
    setupDarkShades: function ($product) {
      var self = this;
      var $allShades = $('.js-product-shade--single', $product);

      $allShades.each(function () {
        var $shade = $(this);

        if (self.isDark($shade.data('hex'))) {
          $shade.addClass('product-shade-picker__shade--dark');
        }
      });
    },

    /**
     * Collects all sold out shades and puts them at
     * the end of the shade picker.
     * @param {Object} $product - jQuery DOM element context.
     * @returns {undefined}
     */
    pushOutOfStockShadesToBottom: function ($product) {
      var $shadesContainer = $('.js-product-shades-container', $product);
      var $allShades = $('.js-product-shade', $shadesContainer);
      var outOfStockShades = [];

      // Add any sold out to temporary storage and remove from DOM.
      $allShades.each(function () {
        var $shade = $(this);

        if ($shade.find('.js-sold-out-icon').length) {
          outOfStockShades.push($shade);
          $shade.remove();
        }
      });

      $shadesContainer.append(outOfStockShades);
    },

    /**
     * Shows scroll controls if all the shades height exceeds the
     * view window. Set up events for the arrow controls.
     * @param {Object} $product - jQuery DOM element context.
     * @returns {undefined}
     */
    setupShadeScroller: function ($product) {
      var $shadesWindow = $('.js-product-shades-window', $product);
      var $shadesContainer = $('.js-product-shades-container', $product);
      var $shadePickerControls = $('.js-product-shade-picker-controls', $product);
      var windowHeight = $shadesWindow.outerHeight(true);
      var containerHeight = $shadesContainer.outerHeight(true);

      $shadesContainer.css('top', 0); // Reset the shades position to zero.

      if (containerHeight <= windowHeight) {
        $shadePickerControls.addClass('hidden');

        return;
      }

      var shadeHeight = $('.js-product-shade', $product).first().outerHeight(true);
      var $shadePickerArrowUp = $('.js-product-shade-arrow-up', $shadePickerControls);
      var $shadePickerArrowDown = $('.js-product-shade-arrow-down', $shadePickerControls);

      $shadePickerControls.removeClass('hidden');

      // On up arrow click, shift the shades container up.
      $shadePickerArrowUp.unbind('click').on('click', function (event) {
        event.preventDefault();
        var currentTop = parseInt($shadesContainer.position().top);

        if (currentTop < 0) {
          $shadesContainer.css('top', currentTop + shadeHeight);
        }
      });

      // On down arrow click, shift the shades container down.
      $shadePickerArrowDown.unbind('click').on('click', function (event) {
        event.preventDefault();
        var currentTop = parseInt($shadesContainer.position().top);

        if (currentTop > windowHeight - containerHeight) {
          $shadesContainer.css('top', currentTop - shadeHeight);
        }
      });
    },

    /**
     * Returns the skuId of the active shade.
     *
     * @returns {Int} The Id of the selected SKU, or the product's sku-base-id
     * if there are no .js-product-shade.active elements on the page.
     */
    getActiveShade: function () {
      var $product = $('.js-product-full');
      var $activeShade = $('.js-product-shade.active', $product);
      var skuId;

      if ($activeShade && $activeShade.data('sku')) {
        skuId = $activeShade.data('sku').substring(3);
      } else {
        skuId = $product.data('sku-base-id');
      }

      return skuId;
    },

    /** Sets up events for this template.
     *  @param {Object} context - the DOM scope, usually document.
     *  @returns {undefined}
     */
    setEvents: function (context) {
      var self = this;

      $(document).on('click', '.js-product-shade', function (event) {
        event.preventDefault();
        var $this = $(this);
        var skuId = $this.data('sku');

        if (skuId) {
          var sku = prodcat.data.getSku(skuId);
          var skuBaseId;

          if (sku) {
            skuBaseId = sku.SKU_BASE_ID;
            var $product = $this.closest('.js-product');

            $product.trigger('product.skuSelect', [skuBaseId]);

            // Toggle the active state of the selected shades.
            $product.find('.js-product-shade').removeClass('active');
            $this.addClass('active');
          }
        }
      });

      $(document).on('product.skuSelect', '.js-product', function (event, skuBaseId) {
        var $this = $(this);

        $('.js-product-shade', $this).removeClass('active');
        $('.js-product-shade[data-sku="SKU' + skuBaseId + '"]', $this).addClass('active');
        self.setShadeDesc(skuBaseId, $this);
        self.toggleEgift(skuBaseId);
        if (Drupal.settings.common.clearpay_sku_price && $('.js-sku-price', this).length > 0) {
          var amountClearpay = $('.js-sku-price', $this).html().replace(/[^\d]/g, '');

          if (amountClearpay !== null && typeof presentClearpay.clearpayConfig === 'function') {
            presentClearpay.clearpayConfig(amountClearpay);
          }
        }
      });

      // When a user hovers over a shade, update the shade description.
      $(document).on('mouseenter', '.js-product-shade', function () {
        var $this = $(this);
        var $product = $this.closest('.js-product');
        var skuId = $this.data('sku');

        self.setShadeDesc(skuId, $product);
      });

      // When a user hovers off the shade picker, reset the shade description
      // to either the active shade OR the base sku id's shade.
      $(document).on('mouseleave', '.js-product-shade', function () {
        var $this = $(this);
        var $product = $this.closest('.js-product');
        var skuId = self.getActiveShade();

        self.setShadeDesc(skuId, $product);
      });

      // On quickshop load, call the appropriate shade picker setup methods.
      $(document).on(
        'product.quickshop.launch.inline product.quickshop.launch.inlineCarousel product.quickshop.launch.overlay',
        function () {
          var $product = $('.js-quickshop');

          self.makeSkuBaseIdActive($product);
          self.setupDarkShades($product);
          self.pushOutOfStockShadesToBottom($product);
          self.setupShadeScroller($product);
        }
      );

      // The EGIFT_ENABLED flag (that determines if there should be a "Egift
      // This!" button) is loaded through a lazily-loaded product query during
      // page initialization. Once that data's loaded (see product_data.js),
      // update the state of the Egift button for the active shade.
      $(document).on('prodcat.data.query.success', function () {
        var skuId = self.getActiveShade();

        self.toggleEgift(skuId);
      });

      // After the user selects a shade color family, there will more than likely
      // be less shades in the picker, so it should be re-setup.
      $(document).on('product.colorFamilySelect', '.js-product-shades-container', function () {
        var $product = $(this).closest('.js-product');

        self.setupShadeScroller($product);
      });
    },

    /**
     * Calls all methods for setting up this template's JS.
     * Separated out in case it must be called after certain triggers too.
     * @param {Object} context - the DOM scope, usually document.
     * @returns {undefined}
     */
    init: function (context) {
      var $product = $('.js-product', context);
      var $productfull = $('.js-product-full', context);

      this.makeSkuBaseIdActive($productfull);
      this.setupDarkShades($product);
      this.pushOutOfStockShadesToBottom($product);
      this.setupShadeScroller($product);
      this.setEvents(context);
    },

    /**
     * Standard method for attaching the template's JS.
     * @param {Object} context - the DOM scope, usually document.
     * @returns {undefined}
     */
    attach: function (context) {
      if (this.attached) {
        return;
      }
      this.attached = true;
      this.init(context);
    }
  };
})(jQuery);
